import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { sv } from 'date-fns/locale';

type Props = {
  value: Date;
  updateValue: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

export default function ValidatedDatePicker({ value, updateValue, minDate, maxDate }: Props): JSX.Element {
  const internalMinDate = minDate ? minDate : new Date(2000, 0);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <DatePicker
        value={value}
        onChange={(newValue: Date | null): void => {
          if (newValue && !isNaN(newValue.getTime())) {
            updateValue(newValue);
          }
        }}
        minDate={internalMinDate}
        maxDate={maxDate}
        disableFuture
      />
    </LocalizationProvider>
  );
}
