import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ChartData, ChartOptions, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import { COMPONENT_PADDING } from '../../../../themes/theme';

export type CategoriesAndValues = {
  categories: string[];
  values: number[];
};

type PieChartProps = {
  colors: string[];
  data: CategoriesAndValues;
  header: string;
  size: number;
};

export default function PieChart(props: PieChartProps): JSX.Element {
  ChartJS.register(CategoryScale, LinearScale, Tooltip);

  const boxSize = COMPONENT_PADDING / 2;

  const theme = useTheme();

  const pieOptions: ChartOptions<'doughnut'> = {
    cutout: '35%',
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: boxSize,
          boxHeight: boxSize,
          color: theme.palette.text.secondary,
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
  };

  const pieData: ChartData<'doughnut'> = {
    labels: props.data.categories,
    datasets: [
      {
        backgroundColor: props.colors,
        borderColor: theme.palette.background.default,
        data: props.data.values,
        borderWidth: 2,
      },
    ],
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: `${props.size}px`,
        height: `${300 - 5}px`,
        gap: `${COMPONENT_PADDING / 2}px`,
      }}
    >
      <Typography variant='graphHeader'>{props.header}</Typography>
      <Doughnut data={pieData} options={pieOptions} />
    </Box>
  );
}
