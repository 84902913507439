import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsCellItemProps,
  gridClasses,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowHeightReturnValue,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
} from '@mui/x-data-grid';
import { renderEditEventType } from './renderEditEventType';
import TextField from '@mui/material/TextField';
import { ROLE_ID_SYSTEM_ADMIN, ServiceLogContent } from '../../../../../model/backendDataModels';
import {
  deleteServiceLog,
  editServiceLog,
  mutateServiceLogs,
  useServiceLogCount,
  useServiceLogs,
} from '../../../../../services/serviceLogManipulation';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Close';
import { LoginContext } from '../../../../../Login';
import { AppContext } from '../../../../../App';
import { useUser } from '../../../../../dataHooks/adminHooks';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type ServiceLogItem = {
  id: number;
  eventId: string;
  time: string;
  expanded: boolean;
  eventType: string;
  description: string;
  status: string;
};

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    backgroundColor: 'black',
  },
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: 'none',
  },
  '& .MuiInputBase-root': {
    padding: 0,
    margin: 0,
  },
  '& .MuiSelect-select': {
    padding: '7px 0px',
    minHeight: 'unset',
  },
  '& .MuiPaper-root': {
    backgroundColor: 'black',
    font: '400 0.875rem Roboto, Helvetica, Arial, sans-serif !important',
  },
  '& .MuiInputBase-input': {
    font: '400 0.875rem Roboto, Helvetica, Arial, sans-serif !important',
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiDataGrid-cell--editing:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    padding: '0px 10px',
  },
  '[aria-selected="false"]': {
    cursor: 'pointer',
  },
  '[aria-selected="true"]': {
    cursor: 'pointer',
  },
})) as typeof DataGrid;

type ServiceLogDataGridProps = {
  mui: string;
};

function isMultiline(value: string): boolean {
  return value.includes('\n');
}

function getFirstLineWithEllipsis(value: string): React.ReactNode {
  const lines = value.split('\n');
  const lineCount = lines.length;
  const firstLine = lines[0];
  const ellipsis = firstLine[firstLine.length - 1] === '.' ? '..' : '...';

  return firstLine + (lineCount > 1 ? ellipsis : '');
}

export default function ServiceLogDataGrid(props: ServiceLogDataGridProps): JSX.Element {
  const appContext = React.useContext(AppContext);
  const loginContext = React.useContext(LoginContext);

  const MAX_ITEMS_PER_PAGE = 25;

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [rows, setRows] = React.useState<GridRowModel<ServiceLogItem>[]>([]);
  const [page, setPage] = React.useState(0);
  const [focusedRowId, setFocusedRowId] = React.useState<number | null>(null);

  const headers: GridColDef[] = [
    {
      field: 'time',
      headerName: 'Time',
      width: 160,
      disableColumnMenu: true,
      resizable: false,
    },
    {
      field: 'eventType',
      headerName: 'Type',
      editable: true,
      width: 175,
      type: 'singleSelect',
      disableColumnMenu: true,
      resizable: false,
      renderEditCell: renderEditEventType,
    },
    {
      field: 'description',
      headerName: 'Description',
      editable: true,
      flex: 1,
      minWidth: 275,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params: GridRenderCellParams): React.ReactNode => (
        <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', margin: '5px 0px' }}>
          {params.row.id === focusedRowId ? (
            params.value.split('\n').map((line: string, index: number) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))
          ) : (
            <span>{getFirstLineWithEllipsis(params.value)}</span>
          )}
        </div>
      ),
      renderEditCell: (params: GridRenderCellParams): React.ReactNode => (
        <TextField
          multiline
          fullWidth
          sx={{ margin: '5px 0px' }}
          value={params.value}
          onChange={(event): void => {
            const newValue = event.target.value;
            params.api.setEditCellValue({ id: params.id, field: 'description', value: newValue });
          }}
        />
      ),
    },
  ];

  function getActions(params: GridRowParams): React.ReactElement<GridActionsCellItemProps>[] {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          key={`${params.id}-save`}
          icon={<DoneIcon />}
          label='Save'
          color='inherit'
          onClick={handleSaveClick(params.id)}
        />,
        <GridActionsCellItem
          key={`${params.id}-cancel`}
          icon={<CancelIcon />}
          label='Cancel'
          className='textPrimary'
          onClick={handleCancelClick(params.id)}
          color='inherit'
        />,
      ];
    }

    return [
      <GridActionsCellItem
        key={`${params.id}-edit`}
        icon={<EditIcon />}
        label='Edit'
        className='textPrimary'
        onClick={handleEditClick(params.id)}
        color='inherit'
      />,
      <GridActionsCellItem
        key={`${params.id}-delete`}
        icon={<DeleteIcon />}
        label='Delete'
        onClick={handleDeleteClick(params.id)}
        color='inherit'
      />,
    ];
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    rows.forEach(async (serviceLog, index) => {
      if (index === id && loginContext.accessToken) {
        await deleteServiceLog(props.mui, serviceLog.eventId, loginContext.accessToken, appContext.addBackendError);
        await mutateServiceLogs(props.mui, page * MAX_ITEMS_PER_PAGE, MAX_ITEMS_PER_PAGE + 1);
        return;
      }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: GridRowModel<ServiceLogItem>): Promise<GridRowModel<ServiceLogItem>> => {
    const updatedRow = { ...newRow };

    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));

    if (loginContext.accessToken) {
      const serviceLogContent: ServiceLogContent = {
        eventType: newRow.eventType,
        description: newRow.description,
        status: newRow.status,
      };

      await editServiceLog(props.mui, newRow.eventId, serviceLogContent, loginContext.accessToken, appContext.addBackendError);
      await mutateServiceLogs(props.mui, page * MAX_ITEMS_PER_PAGE, MAX_ITEMS_PER_PAGE + 1);
    }

    return updatedRow;
  };

  const {
    data: serviceLogCount,
    error: serviceLogCountError,
    isLoading: isServiceLogCountLoading,
  } = useServiceLogCount(props.mui, loginContext.accessToken);
  if (serviceLogCountError) {
    appContext.addBackendError(serviceLogCountError);
  }

  const pageCount = Math.ceil((serviceLogCount?.count ?? 0) / MAX_ITEMS_PER_PAGE);

  const {
    data: serviceLogs,
    error: serviceLogsError,
    isLoading: isServiceLogsLoading,
  } = useServiceLogs(props.mui, page * MAX_ITEMS_PER_PAGE, MAX_ITEMS_PER_PAGE + 1, loginContext.accessToken);
  if (serviceLogsError) {
    appContext.addBackendError(serviceLogsError);
  }

  const { data: user, error: userError } = useUser(loginContext.accessToken);
  if (userError) {
    appContext.addBackendError(userError);
  }

  React.useEffect(() => {
    if (serviceLogs) {
      const gridRows: GridRowModel<ServiceLogItem>[] = [];
      serviceLogs.forEach((serviceLog, index) => {
        if (index === MAX_ITEMS_PER_PAGE) {
          return;
        }

        const createdAt = moment(serviceLog.createdAt, moment.ISO_8601).tz(appContext.timezone).format('YYYY-MM-DD HH:mm:ss');

        gridRows.push({
          id: index,
          eventId: serviceLog.eventId,
          time: createdAt,
          expanded: false,
          eventType: serviceLog.eventType,
          description: serviceLog.description,
          status: serviceLog.status,
        });
      });

      setRows(gridRows);
    }
  }, [serviceLogs, setRows, appContext.timezone]);

  if (user && user.roleId === ROLE_ID_SYSTEM_ADMIN) {
    headers.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'right',
      width: 100,
      cellClassName: 'actions',
      disableColumnMenu: true,
      resizable: false,
      getActions: getActions,
    });
  }

  const handleCellClick: GridEventListener<'cellClick'> = params => {
    if (params.row.id === focusedRowId) {
      setFocusedRowId(null);
    } else {
      setFocusedRowId(params.row.id);
    }
  };

  return (
    <>
      <StyledDataGrid
        loading={isServiceLogsLoading}
        columnHeaderHeight={36}
        disableRowSelectionOnClick
        pageSizeOptions={[MAX_ITEMS_PER_PAGE]}
        processRowUpdate={processRowUpdate}
        columns={headers}
        rows={rows}
        editMode='row'
        rowModesModel={rowModesModel}
        getRowHeight={(): GridRowHeightReturnValue => 'auto'}
        hideFooterPagination={true}
        hideFooter={true}
        onCellClick={handleCellClick}
        isRowSelectable={(params): boolean => isMultiline(params.row.description)}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderTop: '1px solid #30444E', paddingTop: '5px' }}>
        {isServiceLogCountLoading ? (
          <CircularProgress size={32} />
        ) : (
          <Pagination
            count={pageCount}
            page={page + 1}
            onChange={(_event: React.ChangeEvent<unknown>, page: number): void => {
              setPage(page - 1);
            }}
          />
        )}
      </Box>
    </>
  );
}
