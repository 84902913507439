import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartData, BarElement, ChartOptions, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { COMPONENT_PADDING } from '../../../../themes/theme';

export type CategoriesAndValues = {
  categories: string[];
  values: number[] | [number, number][];
};

type BarGraphProps = {
  data: CategoriesAndValues;
  header: string;
  size: number;
  legend: string[];
};

export default function BarGraph(props: BarGraphProps): JSX.Element {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

  const theme = useTheme();

  const barOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 0,
          generateLabels: () => {
            return props.legend.map((label, index) => ({
              text: label,
              fontColor: theme.palette.text.secondary,
              index,
            }));
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        grid: {
          color: theme.palette.action.active,
          drawTicks: false,
        },
        ticks: {
          color: theme.palette.action.active,
        },
      },
      x: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          maxRotation: 0,
          stepSize: 1,
          precision: 0,
          autoSkip: true,
          autoSkipPadding: 6,
          color: theme.palette.action.active,
        },
      },
    },
  };

  const barData: ChartData<'bar'> = {
    labels: props.data.categories,
    datasets: [
      {
        backgroundColor: theme.palette.info.dark,
        data: props.data.values,
        maxBarThickness: props.data.values.length < 30 ? 10 : 5,
      },
    ],
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: `${props.size}px`,
        height: `${300}px`,
        gap: `${COMPONENT_PADDING / 2}px`,
      }}
    >
      <Typography variant='graphHeader'>{props.header}</Typography>
      <Bar data={barData} options={barOptions} plugins={[ChartDataLabels]} />
    </Box>
  );
}
