import React from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import MonthAndYearPicker from '../../common/monthAndYearPicker/MonthAndYearPicker';
import { AppContext } from '../../../App';
import { LoginContext } from '../../../Login';
import { BackendDevice, HistoryData } from '../../../model/backendDataModels';
import { CircularProgress } from '@mui/material';
import { useDeviceHistoryData } from '../../../dataHooks/deviceHooks';
import { COMPONENT_PADDING, WIDTH_OF_INFO_COMPONENT } from '../../../themes/theme';
import BarGraph from './batteryReportTab/BarGraph';
import PieChart from './batteryReportTab/PieChart';
import Store from '../../../store/Store';

type BatteryReportDataProps = {
  data: HistoryData[];
};

function BatteryReportData(props: BatteryReportDataProps): JSX.Element {
  const dischargeActive = props.data.reduce((acc, row) => {
    return acc + row.id136;
  }, 0);
  const dischargeResting = props.data.reduce((acc, row) => {
    return acc + row.id137;
  }, 0);
  const chargeActive = props.data.reduce((acc, row) => {
    return acc + row.id163;
  }, 0);
  const chargeResting = props.data.reduce((acc, row) => {
    return acc + row.id164;
  }, 0);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: `${COMPONENT_PADDING * 3}px`,
        marginTop: `${COMPONENT_PADDING}px`,
        paddingBottom: `${COMPONENT_PADDING * 3}px`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: `${COMPONENT_PADDING}px`,
        }}
      >
        <PieChart
          header='Battery Usage Profile'
          size={WIDTH_OF_INFO_COMPONENT * 2}
          colors={['#0488d3cc', '#0488d399', '#00ba7066', '#00ba7088']}
          data={{
            categories: ['Discharge Active', 'Discharge Resting', 'Charge Resting', 'Charge Active'],
            values: [dischargeActive, dischargeResting, chargeResting, chargeActive],
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: `${COMPONENT_PADDING}px`,
        }}
      >
        <BarGraph
          header='Max Discharge Current'
          size={WIDTH_OF_INFO_COMPONENT - COMPONENT_PADDING}
          legend={[]}
          data={{
            categories: props.data.map((row, index) => (index + 1).toString()),
            values: props.data.map(row => {
              return row.id159;
            }),
          }}
        />
        <BarGraph
          header='Max Charge Current'
          size={WIDTH_OF_INFO_COMPONENT - COMPONENT_PADDING}
          legend={[]}
          data={{
            categories: props.data.map((row, index) => (index + 1).toString()),
            values: props.data.map(row => {
              return row.id186;
            }),
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: `${COMPONENT_PADDING}px`,
        }}
      >
        <BarGraph
          header='Min / Max - Voltage Per Cell'
          size={WIDTH_OF_INFO_COMPONENT - COMPONENT_PADDING}
          legend={[]}
          data={{
            categories: props.data.map((row, index) => (index + 1).toString()),
            values: props.data.map(row => {
              return [Math.round(Math.min(row.id140, row.id165) * 100) / 100, Math.round(Math.max(row.id138, row.id167) * 100) / 100] as [
                number,
                number
              ];
            }),
          }}
        />
        <BarGraph
          header='Min / Max - Temperature'
          size={WIDTH_OF_INFO_COMPONENT - COMPONENT_PADDING}
          legend={[]}
          data={{
            categories: props.data.map((row, index) => (index + 1).toString()),
            values: props.data.map(row => {
              const dischargeMax = row.id145;
              const dischargeMin = row.id146;
              const chargeMax = row.id172;
              const chargeMin = row.id173;

              return [
                Math.round(Math.min(dischargeMin, chargeMin) * 100) / 100,
                Math.round(Math.max(dischargeMax, chargeMax) * 100) / 100,
              ] as [number, number];
            }),
          }}
        />
      </Box>
    </Box>
  );
}

type BatteryReportTabProps = {
  selectedDevice: BackendDevice;
};

export default function BatteryReportTab(props: BatteryReportTabProps): JSX.Element {
  const loginContext = React.useContext(LoginContext);
  const appContext = React.useContext(AppContext);
  const { state, dispatch } = React.useContext(Store);

  const date = state.logFilesSettings.batteryReport
    ? state.logFilesSettings.batteryReport.selectedDate
    : utcToZonedTime(new Date(), appContext.timezone);

  const year = date.getFullYear();
  const month = date.getMonth();

  const startDate = moment(new Date(year, month, 1)).startOf('day');
  const endDate = moment(startDate).add(1, 'month');

  const {
    data: historyLogData,
    error: historyLogError,
    isLoading: isLoadingHistoryLogData,
  } = useDeviceHistoryData('bmu', props.selectedDevice.mui, startDate.toDate(), endDate.toDate(), loginContext.accessToken);

  if (historyLogError) {
    appContext.addBackendError(historyLogError);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        borderTop: '2px solid black',
        flexDirection: 'row',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: `${WIDTH_OF_INFO_COMPONENT * 2 + COMPONENT_PADDING}px`,
          padding: `${COMPONENT_PADDING}px`,
        }}
      >
        <MonthAndYearPicker
          year={year}
          month={month}
          onYearSelected={(year): void =>
            dispatch({
              type: 'SET_BATTERY_REPORT_DATE',
              payload: new Date(year, month, 1),
            })
          }
          onMonthSelected={(month): void =>
            dispatch({
              type: 'SET_BATTERY_REPORT_DATE',
              payload: new Date(year, month, 1),
            })
          }
        />
        {isLoadingHistoryLogData ? <CircularProgress /> : historyLogData ? <BatteryReportData data={historyLogData} /> : null}
      </Box>
    </Box>
  );
}
