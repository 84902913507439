import React, { useState, useEffect, ReactNode } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, useTheme, TableContainer } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon from '../../common/Icon';
import LoadingIndicator from '../../common/LoadingIndicator';
import { SxProps } from '../../common/commonTypes';

type TextComponentProps = {
  value: ReactNode;
  keyIndex: string;
};

function TextComponent({ value, keyIndex }: TextComponentProps): JSX.Element {
  const chapters = typeof value === 'string' ? value.split('\n') : [value];

  return (
    <Typography key={`typo${keyIndex}`} variant='tableText' whiteSpace='nowrap' sx={{ cursor: 'inherit' }}>
      {chapters.map((chapter, index) => {
        return (
          <Box key={`box${keyIndex}.${index}`}>
            {chapter}
            {index < chapters.length - 1 && <br />}
          </Box>
        );
      })}
    </Typography>
  );
}

function isRowExpandable(row: (string | number)[]): boolean {
  return row.some(value => isMultiline(value));
}

function isMultiline(value: string | number): boolean {
  return typeof value === 'string' && value.includes('\n');
}

function getFirstLineWithEllipsis(value: string): React.ReactNode {
  const lines = value.split('\n');
  const lineCount = lines.length;
  const firstLine = lines[0];
  const ellipsis = firstLine[firstLine.length - 1] === '.' ? '..' : '...';

  return firstLine + (lineCount > 1 ? ellipsis : '');
}

function FoldableElement(value: string | number, expanded: boolean): JSX.Element {
  if (typeof value === 'number') {
    return <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', margin: '0px' }}>{value}</div>;
  }

  return (
    <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', margin: '0px' }}>
      {expanded ? (
        value.split('\n').map((line: string, index: number) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))
      ) : (
        <span>{getFirstLineWithEllipsis(value)}</span>
      )}
    </div>
  );
}

type FoldableListProps = {
  headers: ReactNode[];
  data?: (string | number)[][];
  changeDataParameters?: ((sortCol: number, sortAsc: boolean) => void) | null; // set to null to disable sorting
  sortableColumns?: number[];
  alignment?: 'left' | 'right';
};

export default function FoldableList({
  data,
  headers,
  changeDataParameters = null,
  sortableColumns,
  alignment,
  sx,
}: FoldableListProps & SxProps): JSX.Element {
  const [sortCol, setSortCol] = useState<number | undefined>();
  const [sortAsc, setSortAsc] = useState<boolean | undefined>();
  const [expandedRow, setExpandedRow] = useState<number>(-1);

  const textAlignment = alignment ?? 'right';

  useEffect(() => {
    if (sortAsc === undefined && sortCol !== undefined) {
      setSortAsc(true);
      changeDataParameters && changeDataParameters(sortCol, true);
    } else {
      changeDataParameters && sortCol !== undefined && sortAsc !== undefined && changeDataParameters(sortCol, sortAsc);
    }
  }, [sortAsc, sortCol]);

  const theme = useTheme();

  if (!data) {
    return <LoadingIndicator />;
  }

  return (
    <TableContainer sx={sx} component={Box}>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            {headers.map((element, cellIndex) => (
              <TableCell
                key={`headers${cellIndex}`}
                sx={{
                  borderBottom: 'none',
                  cursor:
                    changeDataParameters && ((sortableColumns && sortableColumns.includes(cellIndex)) || !sortableColumns)
                      ? 'pointer'
                      : 'default',
                  userSelect: 'none',
                  whiteSpace: 'nowrap',
                  background: theme.palette.primary.main,
                  textAlign: textAlignment,
                }}
                onClick={(): void => {
                  if (changeDataParameters && ((sortableColumns && sortableColumns.includes(cellIndex)) || !sortableColumns)) {
                    if (cellIndex === sortCol) {
                      setSortAsc(!sortAsc);
                    } else {
                      setSortCol(cellIndex);
                    }
                  }
                }}
              >
                {changeDataParameters &&
                ((sortableColumns && sortableColumns.includes(cellIndex)) || !sortableColumns) &&
                cellIndex === sortCol ? (
                  <Box>
                    <Typography variant='columnHeader' sx={{ color: theme.palette.info.main, cursor: 'pointer' }}>
                      {element}
                    </Typography>
                    <Icon
                      name='arrow'
                      sx={{
                        marginLeft: '5px',
                        color: theme.palette.info.main,
                        transform: sortAsc ? 'translate(0, 50%) rotate(30deg)' : 'rotate(-30deg)',
                      }}
                    />
                  </Box>
                ) : (
                  <Typography
                    variant='columnHeader'
                    sx={{
                      cursor:
                        changeDataParameters && ((sortableColumns && sortableColumns.includes(cellIndex)) || !sortableColumns)
                          ? 'pointer'
                          : 'default',
                    }}
                  >
                    {element}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={`tb${rowIndex}`} onClick={(): void => setExpandedRow(expandedRow === rowIndex ? -1 : rowIndex)}>
                {row.map((element, cellIndex) => {
                  return (
                    <TableCell
                      key={`cell${rowIndex}.${cellIndex}`}
                      sx={{
                        cursor: isRowExpandable(row) ? 'pointer' : 'default',
                        borderBottom: '1px solid #30444E',
                        textAlign: typeof element === 'number' ? 'right' : 'left',
                      }}
                    >
                      <TextComponent
                        key={`text${rowIndex}.${cellIndex}`}
                        keyIndex={`${rowIndex}.${cellIndex}`}
                        value={isMultiline(element) ? FoldableElement(element, expandedRow === rowIndex) : element}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
